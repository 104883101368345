import { FC, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import Button from 'src/components/ui/button/Button'
import Input from 'src/components/ui/input/Input'
import { Phone } from 'src/types/swagger'

interface IProps {
	type: 'base' | 'additional'
	variant: 'change' | 'add'
	data: Phone | null
	onSubmit: (data: any) => void
}

const ProfilePhoneItem: FC<IProps> = ({ onSubmit, type, data, variant }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		setValue
	} = useForm({ defaultValues: { phone: data?.phone_number || '' } })

	const [isChange, setIsChange] = useState(false)

	const handleChange = () => {
		setIsChange(prev => !prev)
	}

	useEffect(() => {
		setValue('phone', data?.phone_number || '')
	}, [isChange])

	return (
		<form onSubmit={handleSubmit(e => onSubmit({ oldPhone: data?.phone_number || '', ...e }))}>
			<div className="profile-page__block-item">
				{!isChange ? (
					<>
						<div className="section__label">
							{type === 'base' ? 'Основной номер' : 'Дополнительный телефон'}
						</div>

						<div className="profile-page__text">{data?.phone_number}</div>
						{variant === 'add' && (
							<div className="profile-page__text">Не заполнено</div>
						)}

						{data?.is_change_phone && variant === 'change' && (
							<div className="profile-page__button" onClick={handleChange}>
								Изменить
							</div>
						)}
						{variant === 'add' && (
							<div className="profile-page__button" onClick={handleChange}>
								Добавить
							</div>
						)}
					</>
				) : (
					<>
						<Input
							type="tel"
							error={errors.phone}
							register={register}
							filled={!!watch('phone')}
							validation={{ required: 'Введите номер телефона' }}
							name="phone"
							placeholder={'Дополнительный телефон'}
						/>
						<div className="r-input-phone__controls">
							<Button
								text="Отменить"
								theme="outline"
								size="small"
								type={'button'}
								onClick={handleChange}
							/>
							<Button text="Применить" size="small" type={'submit'} />
						</div>
					</>
				)}
			</div>
		</form>
	)
}

export default ProfilePhoneItem
