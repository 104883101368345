import React, { FC, useEffect } from 'react'

import { Link, NavLink, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'src/hooks/redusHooks'
import { clearBannersState } from 'src/redux/slices/appSlice'
import {
	COSTCONTROL,
	HOME,
	INFO,
	NEWS,
	PARTNERS,
	PARTNERSFORM,
	PAYMENT,
	SERVICES
} from 'src/routes/data'

import './Sidebar.scss'
import SidebarBanners from './components/ad/SidebarBanners'

const links = [
	{
		title: 'Новости',
		href: NEWS
	},
	// {
	//   title: 'Партнерские программы',
	//   href: PARTNERS,
	//   href2: PARTNERSFORM,
	// },
	{
		title: 'Полезная информация',
		href: INFO
	}
]

export const Sidebar: FC = () => {
	const { pathname } = useLocation()

	return (
		<div className="sidebar r-sidebar">
			<div className="sidebar__container">
				<div className="sidebar__top">
					<nav className="sidebar__nav">
						<div className="sidebar__item">
							<NavLink className="sidebar__item-link" to={HOME}>
								<div className="sidebar__item-icon">
									<svg
										className="sidebar__item-svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13.2265 1.47645C12.8898 1.16972 12.4529 1 12 1C11.5471 1 11.1102 1.16972 10.7735 1.47645L1 10.3806V20.0307C1 20.8182 1.30905 21.5735 1.85915 22.1303C2.40926 22.6872 3.15536 23 3.93333 23H20.0667C20.8446 23 21.5907 22.6872 22.1408 22.1303C22.691 21.5735 23 20.8182 23 20.0307V10.3806L13.2265 1.47645ZM14.75 21.1414H9.25V16.4778C9.25 15.7395 9.53973 15.0315 10.0555 14.5094C10.5712 13.9874 11.2707 13.6941 12 13.6941C12.7293 13.6941 13.4288 13.9874 13.9445 14.5094C14.4603 15.0315 14.75 15.7395 14.75 16.4778V21.1414ZM21.1667 20.0279C21.1667 20.3233 21.0508 20.6065 20.8445 20.8153C20.6382 21.0241 20.3584 21.1414 20.0667 21.1414H16.5833V16.4778C16.5833 15.2473 16.1004 14.0673 15.2409 13.1972C14.3814 12.3271 13.2156 11.8383 12 11.8383C10.7844 11.8383 9.61864 12.3271 8.75909 13.1972C7.89955 14.0673 7.41667 15.2473 7.41667 16.4778V21.1414H3.93333C3.6416 21.1414 3.36181 21.0241 3.15552 20.8153C2.94923 20.6065 2.83333 20.3233 2.83333 20.0279V11.2064L12 2.85531L21.1667 11.2064V20.0279Z"
											fill="#001F38"
										/>
									</svg>
								</div>
								<div className="sidebar__item-text">Мой ПАКТ</div>
							</NavLink>
						</div>

						<div className="sidebar__item">
							<NavLink to={SERVICES} className="sidebar__item-link">
								<div className="sidebar__item-icon">
									<svg
										className="sidebar__item-svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.36364 1H4.63636C3.67194 1 2.74702 1.38312 2.06507 2.06507C1.38312 2.74702 1 3.67194 1 4.63636L1 7.36364C1 8.32806 1.38312 9.25298 2.06507 9.93493C2.74702 10.6169 3.67194 11 4.63636 11H7.36364C8.32806 11 9.25298 10.6169 9.93493 9.93493C10.6169 9.25298 11 8.32806 11 7.36364V4.63636C11 3.67194 10.6169 2.74702 9.93493 2.06507C9.25298 1.38312 8.32806 1 7.36364 1V1ZM9.18182 7.36364C9.18182 7.84585 8.99026 8.30831 8.64928 8.64928C8.30831 8.99026 7.84585 9.18182 7.36364 9.18182H4.63636C4.15415 9.18182 3.69169 8.99026 3.35071 8.64928C3.00974 8.30831 2.81818 7.84585 2.81818 7.36364V4.63636C2.81818 4.15415 3.00974 3.69169 3.35071 3.35071C3.69169 3.00974 4.15415 2.81818 4.63636 2.81818H7.36364C7.84585 2.81818 8.30831 3.00974 8.64928 3.35071C8.99026 3.69169 9.18182 4.15415 9.18182 4.63636V7.36364Z"
											fill="#001F38"
										/>
										<path
											d="M7.36364 13H4.63636C3.67194 13 2.74702 13.3831 2.06507 14.0651C1.38312 14.747 1 15.6719 1 16.6364L1 19.3636C1 20.3281 1.38312 21.253 2.06507 21.9349C2.74702 22.6169 3.67194 23 4.63636 23H7.36364C8.32806 23 9.25298 22.6169 9.93493 21.9349C10.6169 21.253 11 20.3281 11 19.3636V16.6364C11 15.6719 10.6169 14.747 9.93493 14.0651C9.25298 13.3831 8.32806 13 7.36364 13ZM9.18182 19.3636C9.18182 19.8458 8.99026 20.3083 8.64928 20.6493C8.30831 20.9903 7.84585 21.1818 7.36364 21.1818H4.63636C4.15415 21.1818 3.69169 20.9903 3.35071 20.6493C3.00974 20.3083 2.81818 19.8458 2.81818 19.3636V16.6364C2.81818 16.1542 3.00974 15.6917 3.35071 15.3507C3.69169 15.0097 4.15415 14.8182 4.63636 14.8182H7.36364C7.84585 14.8182 8.30831 15.0097 8.64928 15.3507C8.99026 15.6917 9.18182 16.1542 9.18182 16.6364V19.3636Z"
											fill="#001F38"
										/>
										<path
											d="M19.3636 13H16.6364C15.6719 13 14.747 13.3831 14.0651 14.0651C13.3831 14.747 13 15.6719 13 16.6364V19.3636C13 20.3281 13.3831 21.253 14.0651 21.9349C14.747 22.6169 15.6719 23 16.6364 23H19.3636C20.3281 23 21.253 22.6169 21.9349 21.9349C22.6169 21.253 23 20.3281 23 19.3636V16.6364C23 15.6719 22.6169 14.747 21.9349 14.0651C21.253 13.3831 20.3281 13 19.3636 13ZM21.1818 19.3636C21.1818 19.8458 20.9903 20.3083 20.6493 20.6493C20.3083 20.9903 19.8458 21.1818 19.3636 21.1818H16.6364C16.1542 21.1818 15.6917 20.9903 15.3507 20.6493C15.0097 20.3083 14.8182 19.8458 14.8182 19.3636V16.6364C14.8182 16.1542 15.0097 15.6917 15.3507 15.3507C15.6917 15.0097 16.1542 14.8182 16.6364 14.8182H19.3636C19.8458 14.8182 20.3083 15.0097 20.6493 15.3507C20.9903 15.6917 21.1818 16.1542 21.1818 16.6364V19.3636Z"
											fill="#001F38"
										/>
										<path
											d="M13.9 7.4H16.6V10.1C16.6 10.3387 16.6948 10.5676 16.8636 10.7364C17.0324 10.9052 17.2613 11 17.5 11C17.7387 11 17.9676 10.9052 18.1364 10.7364C18.3052 10.5676 18.4 10.3387 18.4 10.1V7.4H21.1C21.3387 7.4 21.5676 7.30518 21.7364 7.1364C21.9052 6.96761 22 6.73869 22 6.5C22 6.2613 21.9052 6.03239 21.7364 5.8636C21.5676 5.69482 21.3387 5.6 21.1 5.6H18.4V2.9C18.4 2.66131 18.3052 2.43239 18.1364 2.2636C17.9676 2.09482 17.7387 2 17.5 2C17.2613 2 17.0324 2.09482 16.8636 2.2636C16.6948 2.43239 16.6 2.66131 16.6 2.9V5.6H13.9C13.6613 5.6 13.4324 5.69482 13.2636 5.8636C13.0948 6.03239 13 6.2613 13 6.5C13 6.73869 13.0948 6.96761 13.2636 7.1364C13.4324 7.30518 13.6613 7.4 13.9 7.4Z"
											fill="#001F38"
										/>
									</svg>
								</div>
								<div className="sidebar__item-text">Услуги</div>
							</NavLink>
						</div>

						<div className="sidebar__item">
							<NavLink to={COSTCONTROL} className="sidebar__item-link">
								<div className="sidebar__item-icon">
									<svg
										className="sidebar__item-svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M18.2223 11.9686H13.8785C13.3897 11.9686 12.9208 11.7743 12.5751 11.4286C12.2294 11.0828 12.0352 10.6139 12.0352 10.1249V5.76846C12.0365 5.34441 11.9402 4.92576 11.7539 4.54486C11.5675 4.16396 11.2961 3.83102 10.9606 3.57179C10.6426 3.31996 10.2711 3.1446 9.8746 3.05925C9.47812 2.9739 9.06733 2.98084 8.67396 3.07954C6.43955 3.6343 4.46138 4.9352 3.06659 6.76712C1.6718 8.59903 0.943938 10.8522 1.00337 13.1541C1.0628 15.4559 1.90598 17.6685 3.39344 19.426C4.88091 21.1835 6.92358 22.3805 9.18363 22.8192C11.7247 23.3056 14.3556 22.802 16.5375 21.4115C18.7195 20.021 20.2873 17.8487 20.92 15.3396C21.019 14.9457 21.0262 14.5342 20.9411 14.137C20.8559 13.7399 20.6806 13.3676 20.4287 13.0489C20.1665 12.7138 19.8317 12.4425 19.4495 12.2554C19.0674 12.0682 18.6478 11.9702 18.2223 11.9686ZM19.1329 14.8888C18.78 16.3102 18.056 17.6122 17.0349 18.6618C16.0138 19.7114 14.7323 20.471 13.3214 20.8627C11.9105 21.2544 10.421 21.2643 9.00501 20.8914C7.58907 20.5184 6.29758 19.776 5.26265 18.74C4.22772 17.704 3.48648 16.4117 3.11475 14.9952C2.74302 13.5787 2.75415 12.0889 3.14699 10.6781C3.53982 9.26734 4.30028 7.98623 5.35057 6.96584C6.40086 5.94545 7.70329 5.22239 9.12464 4.87062C9.24624 4.84024 9.37326 4.83875 9.49554 4.86628C9.61781 4.89381 9.73194 4.9496 9.82879 5.02917C9.94316 5.11638 10.0359 5.22877 10.0998 5.35762C10.1638 5.48646 10.1972 5.62831 10.1974 5.77215V10.1249C10.1974 11.1029 10.5859 12.0407 11.2772 12.7322C11.9686 13.4237 12.9063 13.8122 13.8841 13.8122H18.2269C18.372 13.8128 18.515 13.8463 18.6453 13.9101C18.7755 13.974 18.8896 14.0666 18.979 14.1809C19.0573 14.2791 19.1119 14.3941 19.1384 14.5169C19.165 14.6397 19.1627 14.767 19.132 14.8888H19.1329Z"
											fill="#001F38"
										/>
										<path
											d="M22.9298 7.63033C22.503 6.05996 21.6738 4.62835 20.5244 3.47717C19.3749 2.326 17.945 1.49516 16.3762 1.06689C16.2117 1.02264 16.0421 1.00018 15.8718 1.00008C15.6268 0.997755 15.3838 1.04403 15.1568 1.13624C14.9298 1.22846 14.7233 1.36477 14.5493 1.53732C14.3752 1.70987 14.2371 1.91522 14.1428 2.14151C14.0485 2.36781 14 2.61056 14 2.85574V7.21652C14 7.95475 14.293 8.66273 14.8146 9.18474C15.3362 9.70674 16.0437 10 16.7813 10H21.1563C21.4425 9.99724 21.7241 9.9285 21.9794 9.79911C22.2347 9.66971 22.4568 9.48315 22.6284 9.25392C22.7999 9.02469 22.9164 8.75896 22.9687 8.47738C23.021 8.1958 23.0077 7.90594 22.9298 7.63033ZM20.6139 8.14435H16.7776C16.5317 8.14435 16.2959 8.04659 16.122 7.87259C15.9482 7.69859 15.8505 7.4626 15.8505 7.21652L15.8431 2.86223C15.8499 2.85732 15.8579 2.85443 15.8663 2.85388H15.8904C17.1532 3.19818 18.3037 3.86791 19.227 4.79623C20.1504 5.72455 20.8143 6.87899 21.1526 8.14435H20.6139Z"
											fill="#001F38"
										/>
									</svg>
								</div>
								<div className="sidebar__item-text">Контроль расходов</div>
							</NavLink>
						</div>
						<div className="sidebar__item">
							<Link
								className={`sidebar__item-link ${
									pathname.startsWith(`${PAYMENT}/`) ? 'active' : ''
								}`}
								to={PAYMENT + '/balance'}
							>
								<div className="sidebar__item-icon">
									<svg
										className="sidebar__item-svg"
										width={24}
										height={24}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6 17C6.82843 17 7.5 16.3284 7.5 15.5C7.5 14.6716 6.82843 14 6 14C5.17157 14 4.5 14.6716 4.5 15.5C4.5 16.3284 5.17157 17 6 17Z"
											fill="#001F38"
										/>
										<path
											d="M18.7083 4H5.29167C4.0213 4.0015 2.80341 4.4995 1.90513 5.38476C1.00685 6.27003 0.501522 7.47027 0.5 8.72222L0.5 16.2778C0.501522 17.5297 1.00685 18.73 1.90513 19.6152C2.80341 20.5005 4.0213 20.9985 5.29167 21H18.7083C19.9787 20.9985 21.1966 20.5005 22.0949 19.6152C22.9932 18.73 23.4985 17.5297 23.5 16.2778V8.72222C23.4985 7.47027 22.9932 6.27003 22.0949 5.38476C21.1966 4.4995 19.9787 4.0015 18.7083 4ZM5.29167 5.88889H18.7083C19.4708 5.88889 20.2021 6.1874 20.7413 6.71875C21.2804 7.25011 21.5833 7.97078 21.5833 8.72222H2.41667C2.41667 7.97078 2.71957 7.25011 3.25873 6.71875C3.7979 6.1874 4.52917 5.88889 5.29167 5.88889ZM18.7083 19.1111H5.29167C4.52917 19.1111 3.7979 18.8126 3.25873 18.2812C2.71957 17.7499 2.41667 17.0292 2.41667 16.2778V10.6111H21.5833V16.2778C21.5833 17.0292 21.2804 17.7499 20.7413 18.2812C20.2021 18.8126 19.4708 19.1111 18.7083 19.1111Z"
											fill="#001F38"
										/>
									</svg>
								</div>
								<div className="sidebar__item-text">Оплата</div>
							</Link>
						</div>
					</nav>

					<ul className="sidebar__list">
						{links.map(link => (
							<li className="sidebar__list-item" key={link.title}>
								<NavLink to={link.href} className="sidebar__link">
									{link.title}
								</NavLink>
							</li>
						))}
					</ul>

					<nav className="r-sidebar__site">
						<a href="https://pakt.ru/" className="sidebar__link" target="blank">
							Официальный сайт
						</a>
					</nav>
				</div>
				<SidebarBanners />
			</div>
		</div>
	)
}
