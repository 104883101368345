import { FC } from 'react'

import { useParams } from 'react-router-dom'
import Panel from 'src/components/common/panel/Panel'
import ServiceAvailableOptions from 'src/components/common/services/availableOptions/ServiceAvailableOptions'

const ServiceAvailableOptionsPage: FC = () => {
	const { service_cid } = useParams()

	return (
		<div className="services-page connected-page">
			<Panel text="Доступные" linkText="Услуги" onBack={true} />
			<section className="section section--first-panel r-panel-tubs">
				<div className="container">
					<ServiceAvailableOptions service_cid={service_cid || ''} />
				</div>
			</section>
		</div>
	)
}

export default ServiceAvailableOptionsPage
