import { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { API_DOMAIN } from 'src/api/service/endpoints'
import $api from 'src/api/service/request'
import Panel from 'src/components/common/panel/Panel'
import Loader from 'src/components/ui/loader/Loader'
import { SERVICES } from 'src/routes/data'
import { ResponseServicesRec, ServicesRec } from 'src/types/swagger'

import VideoConnected from '../connected/VideoConnected'

const VideoSettings = () => {
	const { id } = useParams()
	const [data, setData] = useState<ServicesRec | null>(null)
	const [isLoading, setIsLoading] = useState(true)

	//Первичные данные
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServicesRec> = await $api.get(
					`${API_DOMAIN}services/rec/${id}/`
				)

				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch video:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичные данные

	//прелоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}
	//прелоадер

	return (
		data && (
			<div className="services-page connected-page">
				<Panel text="Подключенные" linkText="Услуги" href={SERVICES} />
				<section className="section section--first-panel r-panel-tubs">
					<div className="container">
						<VideoConnected data={data} />
					</div>
				</section>
			</div>
		)
	)
}

export default VideoSettings
