import { FC } from 'react'

import Modal from 'src/components/ui/modal/Modal'
import { Channels } from 'src/types/swagger'

import './TvChannelList.scss'

interface IProps {
	state: boolean
	setState: (prev: boolean) => void
	data: Channels
}

const TvChannelList: FC<IProps> = ({ state, setState, data }) => {
	return (
		<Modal state={state} setState={setState}>
			<div
				className="modules-modal modal fancybox-content active react-channels-modal"
				style={{ display: 'inline-block' }}
			>
				<div className="modules-modal__channels">
					<div className="modules-modal__channels-top">
						<div className="modules-modal__channels-wrapper">
							<div
								className="modules-modal__arrow"
								onClick={() => {
									setState(false)
								}}
							>
								<svg
									className="modules-modal__arrow-svg"
									width={24}
									height={24}
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M19 10.9998H9L12.29 7.70981C12.3837 7.61685 12.4581 7.50625 12.5089 7.38439C12.5597 7.26253 12.5858 7.13183 12.5858 6.99981C12.5858 6.8678 12.5597 6.7371 12.5089 6.61524C12.4581 6.49338 12.3837 6.38278 12.29 6.28982C12.1026 6.10356 11.8492 5.99902 11.585 5.99902C11.3208 5.99902 11.0674 6.10356 10.88 6.28982L6.59 10.5898C6.21441 10.9632 6.00223 11.4702 6 11.9998C6.00487 12.5259 6.21684 13.0289 6.59 13.3998L10.88 17.6998C10.9732 17.7924 11.0838 17.8657 11.2054 17.9155C11.3269 17.9654 11.4571 17.9908 11.5885 17.9904C11.7199 17.9899 11.8499 17.9636 11.9712 17.9128C12.0924 17.8621 12.2024 17.788 12.295 17.6948C12.3876 17.6016 12.4609 17.491 12.5107 17.3694C12.5606 17.2479 12.586 17.1177 12.5856 16.9863C12.5851 16.8549 12.5588 16.7249 12.508 16.6036C12.4573 16.4824 12.3832 16.3724 12.29 16.2798L9 12.9998H19C19.2652 12.9998 19.5196 12.8945 19.7071 12.7069C19.8946 12.5194 20 12.265 20 11.9998C20 11.7346 19.8946 11.4802 19.7071 11.2927C19.5196 11.1052 19.2652 10.9998 19 10.9998Z"
										fill="#0076D8"
									/>
								</svg>
							</div>
						</div>
						<div className="modules-modal__title">{data?.packet_name}</div>
						<div className="modules-modal__text">{data?.description}</div>
					</div>
					<div className="modules-modal__channels-container">
						<div className="modules-modal__channels-grid">
							{data?.channels?.map(channel => (
								<div className="modules-modal__channels-item" key={channel.name}>
									{channel.name} {channel.is_hd && <span>hd</span>}
								</div>
							))}
						</div>
					</div>
				</div>
				<div
					className="modal__icon"
					onClick={() => {
						setState(false)
					}}
				>
					<svg
						className="modal__svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
							fill="#001F38"
						/>
					</svg>
				</div>
				<button
					type="button"
					className="fancybox-button fancybox-close-small"
					title="Close"
				>
					<svg viewBox="0 0 24 24">
						<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
					</svg>
				</button>
			</div>
		</Modal>
	)
}

export default TvChannelList
