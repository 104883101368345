import React, { FC, useState } from 'react'

import FeedBackSidebar from './feedBackSidebar/FeedBackSidebar'

const FeedBack: FC = () => {
	const [isSidebarActive, setIsSidebarActive] = useState(false)

	return (
		<>
			<FeedBackSidebar state={isSidebarActive} setState={setIsSidebarActive} />
			<div
				className="feedback"
				data-fancy-button="feedback"
				onClick={() => setIsSidebarActive(prev => !prev)}
			>
				<div className="feedback__icon">
					<svg
						className="feedback__svg"
						xmlns="http://www.w3.org/2000/svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M23 15.6669V20.2501C23 20.9794 22.7103 21.6789 22.1945 22.1946C21.6788 22.7103 20.9793 23 20.25 23H15.6667C14.3807 22.9987 13.1176 22.6592 12.0043 22.0157C10.8909 21.3721 9.96633 20.4471 9.32333 19.3334C10.0144 19.3285 10.7031 19.2523 11.3785 19.1061C11.8929 19.7493 12.5454 20.2684 13.2877 20.6251C14.03 20.9818 14.8431 21.1669 15.6667 21.1667H20.25C20.4931 21.1667 20.7263 21.0701 20.8982 20.8982C21.0701 20.7263 21.1667 20.4932 21.1667 20.2501V15.6669C21.1665 14.843 20.9808 14.0298 20.6234 13.2875C20.2661 12.5452 19.7462 11.8928 19.1023 11.3788C19.2498 10.7035 19.3272 10.0148 19.3333 9.32369C20.447 9.96667 21.3721 10.8912 22.0156 12.0045C22.6592 13.1179 22.9987 14.3809 23 15.6669ZM17.4789 9.84709C17.5646 8.66612 17.3952 7.48054 16.9819 6.3709C16.5687 5.26126 15.9215 4.25357 15.0842 3.41631C14.2469 2.57904 13.2392 1.93182 12.1295 1.51862C11.0199 1.10542 9.83425 0.935923 8.65325 1.02165C6.56042 1.26065 4.62718 2.25653 3.21758 3.82176C1.80797 5.38699 1.01927 7.41356 1 9.51985L1 14.1397C1 16.4607 2.38142 17.5001 3.75 17.5001H8.975C11.0822 17.482 13.1101 16.6939 14.6765 15.2842C16.2429 13.8745 17.2396 11.9407 17.4789 9.84709ZM13.7875 4.71389C14.4383 5.36608 14.9414 6.15045 15.2627 7.01392C15.5841 7.87739 15.7162 8.7998 15.6502 9.71876C15.4545 11.3538 14.668 12.8608 13.4386 13.9563C12.2091 15.0518 10.6217 15.6602 8.975 15.6669H3.75C2.89933 15.6669 2.83333 14.4981 2.83333 14.1397V9.51985C2.84098 7.8739 3.44993 6.2875 4.54559 5.05917C5.64125 3.83084 7.14808 3.0453 8.7825 2.85035C8.93467 2.83936 9.08683 2.83386 9.239 2.83386C10.0835 2.83307 10.9199 2.9987 11.7003 3.32129C12.4808 3.64387 13.19 4.11709 13.7875 4.71389Z"
							fill="#0076D8"
						/>
						<circle
							cx={18}
							cy={6}
							r={5}
							fill="#EC003E"
							stroke="white"
							strokeWidth={2}
						/>
					</svg>
				</div>
				<div className="feedback__text">Обратная связь</div>
			</div>
		</>
	)
}

export default FeedBack
