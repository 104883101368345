import { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { API_DOMAIN } from 'src/api/service/endpoints'
import $api from 'src/api/service/request'
import Panel from 'src/components/common/panel/Panel'
import Loader from 'src/components/ui/loader/Loader'
import { SERVICES } from 'src/routes/data'
import {
	ResponseServiceCollectiveRadio,
	ServiceCollectiveRadio,
	ServiceUDomofon
} from 'src/types/swagger'

import ServiceAvailableOptions from '../availableOptions/ServiceAvailableOptions'
import UdomofonConnected from '../udomofon/connected/UdomofonConnected'

const RadioSettings = () => {
	const { id } = useParams()
	const [data, setData] = useState<ServiceCollectiveRadio>()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServiceCollectiveRadio> = await $api.get(
					`${API_DOMAIN}services/collective_radio/${id}/`
				)

				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch radio:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		data && (
			<div className="services-page connected-page">
				<Panel text="Подключенные" linkText="Услуги" href={SERVICES} />
				<section className="section section--first-panel">
					<div className="container">
						<Tabs>
							<div className="main-page__services-top">
								<TabList>
									<div className="sections">
										<div className="sections__list">
											<Tab
												className="sections__tab"
												selectedClassName="active"
											>
												Мои опции
											</Tab>
											<Tab
												className="sections__tab"
												selectedClassName="active"
											>
												Доступные опции
											</Tab>
										</div>
									</div>
								</TabList>
							</div>
							<TabPanel>
								<UdomofonConnected data={data as ServiceUDomofon} isRadio />
							</TabPanel>

							<TabPanel>
								<ServiceAvailableOptions service_cid={data.service_cid} />
							</TabPanel>
						</Tabs>
					</div>
				</section>
			</div>
		)
	)
}

export default RadioSettings
