import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { API_DOMAIN } from 'src/api/service/endpoints'
import $api from 'src/api/service/request'
import Loader from 'src/components/ui/loader/Loader'
import { SERVICES } from 'src/routes/data'
import { ResponseServiceTv, ServiceTv } from 'src/types/swagger'

import Panel from '../../../panel/Panel'

import './TvSettings.scss'
import TvSettingConnected from './connected/TvSettingConnected'

const Tv: FC = () => {
	const [data, setData] = useState<ServiceTv | null>(null)
	const { id } = useParams()
	const [isLoading, setLoading] = useState(false)

	//первичное получение данных
	useEffect(() => {
		setLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServiceTv> = await $api.get(
					`${API_DOMAIN}services/tv/${id}/`
				)
				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch tv:', error.message)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [])
	//первичное получение данных

	if (isLoading) {
		return <Loader state={isLoading} />
	}
	return (
		data && (
			<>
				<div className="services-page connected-page">
					<Panel text="Подключенные" linkText="Услуги" href={SERVICES} />
					<section className="section section--first-panel r-tv-settings">
						<div className="container">
							<div className="services-page__inner">
								<TvSettingConnected data={data} />
							</div>
						</div>
					</section>
				</div>
			</>
		)
	)
}

export default Tv
