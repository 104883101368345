export const DOMAIN = 'https://papi-test.pakt.ru/'
export const DOMAIN_PRODUCTION = 'https://papi.pakt.ru/'
export const API_DOMAIN =
	process.env.REACT_APP_ENV !== 'production' ? `${DOMAIN}api/v1/` : `${DOMAIN_PRODUCTION}api/v1/`

// auth
export const LOGIN = `${API_DOMAIN}auth/login/`
export const REFRESH_TOKEN = `${API_DOMAIN}auth/token/refresh`

// client
export const CLIENT = `${API_DOMAIN}clients/`
