import { FC } from 'react'

import Svg from 'src/components/ui/svg/Svg'
import TooltipUi from 'src/components/ui/tooltip/TooltipUi'
import { SERVICEAVAILABLE } from 'src/routes/data'
import { AvailableService } from 'src/types/swagger'

import ServiceButton from '../ui/serviceButton/ServiceButton'

import './AvailableCard.scss'

interface IProps {
	data: AvailableService
}

const AvailableCard: FC<IProps> = ({ data }) => {
	//Склонение слова "тариф"
	function declineTariff(plansCount: number) {
		const lastDigit = plansCount % 10
		const lastTwoDigits = plansCount % 100

		if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
			return plansCount + ' ' + 'тарифов'
		} else if (lastDigit === 1) {
			return plansCount + ' ' + 'тариф'
		} else if (lastDigit >= 2 && lastDigit <= 4) {
			return plansCount + ' ' + 'тарифа'
		} else {
			return plansCount + ' ' + 'тарифов'
		}
	}
	//Склонение слова "тариф"

	return (
		<div className={`service-card ${data.is_promo && 'service-card--alt'} `}>
			<div className="service-card__container">
				<div className="service-card__top">
					<div className="service-card__icon">
						<Svg type={data.service_type} />
					</div>
					<div className="service-card__title">{data.name}</div>
					<TooltipUi text={data.description} type={'more'} />
				</div>
				<div className="service-card__bot">
					<div className="service-card__bot-row">
						<div className="service-card__col">
							{data.plans_count && (
								<div className="service-card__count">
									{declineTariff(data.plans_count)}
								</div>
							)}
						</div>
						<div className="service-card__col">
							<ServiceButton
								title={data.plans_btn_text || 'Смотреть тарифы'}
								href={SERVICEAVAILABLE + '/' + data.service_type + '/'}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AvailableCard
