import { FC, useState } from 'react'

//@ts-ignore
import InputMask from 'react-input-mask'
import { ERROR } from 'src/constants/constants'
import { validatePhoneNumber } from 'src/utils.js/validatePhoneNumber'

import InputError from '../inputError/InputError'

import './input.scss'

interface IProps {
	filled?: boolean
	variant?: 'border'
	placeholder?: string | number
	register?: any
	name?: string
	options?: any
	type?: string
	isPassword?: boolean
	button?: boolean
	error?: any
	isControlsShow?: boolean
	validation?: any
	onChange?: (value: string) => void
	value?: any
}

const Input: FC<IProps> = ({
	filled,
	variant,
	placeholder,
	register,
	name,
	options,
	type,
	isPassword,
	validation,
	button,
	error,
	value,
	onChange
}) => {
	const [showPassword, setShowPassword] = useState(false)
	const [inputValue, setInputValue] = useState('')

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange && onChange(event.target.value)
		setInputValue(event.target.value)
	}

	const togglePasswordVisibility = () => {
		setShowPassword(prevShowPassword => !prevShowPassword)
	}

	const phoneValidate = (value: number) => {
		return Boolean(validatePhoneNumber(value)) || ERROR.PHONE_NUMBER_INVALID
	}

	return (
		<div className="relative">
			<div
				className={`input-ui custom-placeholder   ${
					variant === 'border' ? 'input-ui_base' : 'input-ui_outline'
				}`}
			>
				{type !== 'tel' && (
					<input
						className="input"
						placeholder={placeholder}
						{...register(
							name,
							validation || options,
							onChange && { onChange: handleInputChange },
							{
								value
							}
						)}
						type={showPassword ? 'text' : type || 'text'}
						value={value}
					/>
				)}

				{type === 'tel' && (
					<InputMask
						className="input"
						mask="+7 (999) 999 99 99"
						{...register(name, {
							...validation,
							validate: { phoneValidate }
						})}
					/>
				)}

				<div className="custom-placeholder__service">
					<span>{placeholder}</span>
				</div>

				{isPassword && (
					<div className="password" onClick={togglePasswordVisibility}>
						<button className="password__icon" data-password-btn="" type="button">
							<svg
								className="password__svg"
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
							>
								<svg
									className="password__svg"
									xmlns="http://www.w3.org/2000/svg"
									width={24}
									height={24}
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M21.389 9.79048C20.097 7.62804 17.1581 4 12 4C6.84191 4 3.903 7.62804 2.61098 9.79048C2.21157 10.4544 2 11.2195 2 12C2 12.7805 2.21157 13.5456 2.61098 14.2095C3.903 16.372 6.84191 20 12 20C17.1581 20 20.097 16.372 21.389 14.2095C21.7884 13.5456 22 12.7805 22 12C22 11.2195 21.7884 10.4544 21.389 9.79048ZM19.9687 13.3132C18.8591 15.1675 16.3476 18.2879 12 18.2879C7.65244 18.2879 5.14087 15.1675 4.03129 13.3132C3.79399 12.9186 3.6683 12.4639 3.6683 12C3.6683 11.5361 3.79399 11.0814 4.03129 10.6868C5.14087 8.83253 7.65244 5.71215 12 5.71215C16.3476 5.71215 18.8591 8.82911 19.9687 10.6868C20.206 11.0814 20.3317 11.5361 20.3317 12C20.3317 12.4639 20.206 12.9186 19.9687 13.3132Z"
										fill="#929CA5"
									/>
									<path
										d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11992 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C15.9987 10.9395 15.5769 9.92285 14.827 9.17298C14.0772 8.42311 13.0605 8.00127 12 8ZM12 14.4C11.5253 14.4 11.0613 14.2592 10.6666 13.9955C10.272 13.7318 9.96434 13.357 9.78269 12.9184C9.60104 12.4799 9.55351 11.9973 9.64612 11.5318C9.73872 11.0662 9.9673 10.6386 10.3029 10.3029C10.6386 9.9673 11.0662 9.73872 11.5318 9.64611C11.9973 9.55351 12.4799 9.60104 12.9184 9.78269C13.357 9.96434 13.7318 10.272 13.9955 10.6666C14.2592 11.0613 14.4 11.5253 14.4 12C14.4 12.6365 14.1471 13.247 13.6971 13.6971C13.247 14.1471 12.6365 14.4 12 14.4Z"
										fill="#929CA5"
									/>
								</svg>
								;
							</svg>
						</button>
					</div>
				)}

				{button && (
					<button type="submit" className="connected-page__service-icon">
						<svg
							className="connected-page__service-svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M20.5745 6.23322L9.05963 17.1836C8.98221 17.2575 8.89021 17.3162 8.7889 17.3562C8.68759 17.3962 8.57897 17.4168 8.46926 17.4168C8.35956 17.4168 8.25094 17.3962 8.14963 17.3562C8.04832 17.3162 7.95632 17.2575 7.8789 17.1836L3.42595 12.9447C3.34853 12.8708 3.25653 12.8121 3.15522 12.7721C3.05391 12.7321 2.94529 12.7115 2.83559 12.7115C2.72588 12.7115 2.61726 12.7321 2.51595 12.7721C2.41464 12.8121 2.32264 12.8708 2.24522 12.9447C2.1675 13.0183 2.10582 13.1058 2.06374 13.2022C2.02166 13.2985 2 13.4018 2 13.5061C2 13.6105 2.02166 13.7138 2.06374 13.8101C2.10582 13.9065 2.1675 13.994 2.24522 14.0676L6.69983 18.3034C7.16975 18.7495 7.80657 19 8.47051 19C9.13446 19 9.77128 18.7495 10.2412 18.3034L21.7552 7.35536C21.8328 7.28174 21.8944 7.19429 21.9364 7.09802C21.9784 7.00174 22 6.89853 22 6.79429C22 6.69005 21.9784 6.58684 21.9364 6.49056C21.8944 6.39428 21.8328 6.30683 21.7552 6.23322C21.6778 6.1593 21.5858 6.10064 21.4845 6.06062C21.3832 6.0206 21.2745 6 21.1648 6C21.0551 6 20.9465 6.0206 20.8452 6.06062C20.7439 6.10064 20.6519 6.1593 20.5745 6.23322Z"
								fill="#0076D8"
							/>
						</svg>
					</button>
				)}
			</div>

			{error && <InputError text={error.message} />}
		</div>
	)
}

export default Input
