import { FC } from 'react'

import InputMask from 'react-input-mask'

import Button from '../button/Button'
import InputError from '../inputError/InputError'

import './InputPhone.scss'

interface IProps {
	placeholder?: string
	type?: string
	variant?: string
	error?: string
	name?: string | number
	value: string
	setValue: (value: string) => void
	isControlsShown?: boolean
	submitHidden?: boolean
	onClickClose?: () => void
	onClickSubmit?: () => void
}
const InputPhone: FC<IProps> = ({
	placeholder,
	type = 'text',
	variant,
	error,
	name,
	value,
	setValue,
	submitHidden,
	isControlsShown,
	onClickClose,
	onClickSubmit,
	...props
}) => {
	return (
		<div className="relative r-input-phone">
			<div
				className={`input-wrapper ${value ? 'filled' : ''} ${
					variant === 'border' ? '' : 'input-wrapper--secondary'
				}`}
			>
				<div className="input-wrapper__placeholder">{placeholder}</div>

				<InputMask
					mask="+7 (999) 999-99-99"
					className="input"
					value={value ?? ''}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
				></InputMask>
				{isControlsShown && onClickSubmit && onClickClose && (
					<div className="r-input-phone__controls">
						<Button
							text="Отменить"
							theme="outline"
							size="small"
							type={'button'}
							onClick={onClickClose}
						/>
						<Button
							text="Применить"
							size="small"
							type={'submit'}
							onClick={onClickSubmit}
						/>
					</div>
				)}
			</div>
			{error && <InputError text={error} />}
		</div>
	)
}

export default InputPhone
