import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import AnnounceUi from 'src/components/ui/announce/Announce'
import Button from 'src/components/ui/button/Button'
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi'
import CopyButton from 'src/components/ui/copyButton/CopyButton'
import useModal from 'src/hooks/useModal'
import { CONDITIONSPAGE } from 'src/routes/data'
import { Announce, BaseResponse, Credit as ICredit } from 'src/types/swagger'

import './Credit.scss'

interface IProps {
	data: ICredit
	isLoading: boolean
	setIsLoading: (value: boolean) => void
}

const Credit: FC<IProps> = ({ data, setIsLoading }) => {
	const [announces, setAnnounces] = useState<Announce[] | null>(null)
	const { open } = useModal()

	//синие уведомления для пользователя
	useEffect(() => {
		if (data.announces) {
			const announcesData = data.announces

			// Дополнительная проверка на null для каждого элемента массива
			const sortedAnnounces = announcesData
				.filter(announce => announce != null)
				.sort((a, b) => {
					return a.announce_id - b.announce_id
				})

			setAnnounces(sortedAnnounces)
		}
	}, [data])
	//синие уведомления для пользователя

	//Отправка ( подключение условноного платежа )
	const onSubmit = () => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<BaseResponse> = await $api.put(
					`${API_DOMAIN}clients/${await getFromLocalStorage('agreement')}/credit/`
				)
				const { data } = response
				open({
					text: data?.data?.title || data?.client_message || 'Изменения сохранены',
					modalType: 'success',
					isReload: true,
					description: data?.data?.description || ''
				})
			} catch (error: any) {
				console.info('error', error)
				open({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	//Отправка ( подключение условноного платежа )

	return (
		<>
			{data && (
				<div className="payment-page__block active">
					{/* //Анонсы */}
					{announces && (
						<div className="react-payment-credit__announces">
							{announces.map(item => (
								<AnnounceUi key={item.announce_id} text={item.message} />
							))}
						</div>
					)}
					{/* //Анонсы */}

					<div className="payment-page__conditional">
						{data.is_active && !data.is_credit_available ? (
							<div className="payment-page__inner">
								<div className="section__container">
									<div className="payment-page__wrapper">
										<h2 className="section__title">Условный платеж</h2>
										<div className="payment-page__conditional-grid">
											<div className="payment-page__conditional-item">
												<div className="payment-page__label">
													Дата подключения
												</div>
												<div className="payment-page__text payment-page__conditional-text">
													{data.credit_start_date}
												</div>
											</div>
											<div className="payment-page__conditional-item">
												<div className="payment-page__label">
													Сумма пополнения
												</div>
												<div className="payment-page__text payment-page__conditional-text">
													{data.amount} ₽
												</div>
											</div>
											<div className="payment-page__conditional-item">
												<div className="payment-page__label">
													Действует ещё
												</div>
												<div className="payment-page__text payment-page__conditional-text">
													{data.time_remaining} (до{' '}
													{data.credit_expiry_date})
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="section__container">
								<div className="payment-page__wrapper">
									<h2 className="section__title">Условный платеж</h2>
									<div className="payment-page__conditional-inner">
										<div className="payment-page__conditional-label">
											{data.description_deferral_period}
										</div>
									</div>
									{data.agreement && (
										<div className="payment-page__conditional-wrapper">
											<div className="payment-page__label">
												Номер договора
											</div>
											<div className="payment-page__refill-copy">
												<CopyButton
													text={data.agreement}
													size={true}
													variant={2}
												/>
											</div>
										</div>
									)}
									<div className="payment-page__bot">
										<div className="payment-page__bot-col">
											{/* //Блок вам доступно */}
											{data.is_credit_available && (
												<>
													<div className="payment-page__bot-title">
														Вам доступно:
													</div>
													<div className="payment-page__bot-inner">
														<div className="payment-page__bot-item">
															<div className="payment-page__label">
																Срок действия
															</div>
															<div className="payment-page__text">
																{data.credit_duration} дней (до{' '}
																{data.credit_expiry_date})
															</div>
														</div>
														<div className="payment-page__bot-item">
															<div className="payment-page__label">
																Сумма пополнения
															</div>
															<div className="payment-page__text">
																{data.amount} ₽
															</div>
														</div>
													</div>
												</>
											)}
											{/* //Блок вам доступно */}
										</div>
										<div className="payment-page__bot-col">
											{/* //Сообщение об ошибке */}
											{data.notification_error && (
												<div className="payment-page__conditional-error">
													{data.notification_error}
												</div>
											)}
											{/* //Сообщение об ошибке */}

											<Button
												text="Подключить"
												disabled={!data.is_credit_available}
												onClick={onSubmit}
											/>

											{/* //ссылка на условия  */}
											<ConditionUi data={data.condition_block} />

											{/* //ссылка на условия  */}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}

export default Credit
