// main
export const HOME = '/'
export const MAIN = '/main'

// auth
export const LOGIN = '/login'
// auth

// services
export const SERVICES = '/services'
export const SERVICESTABAVAILABLE = '/services/available'
export const SERVICESTABHISTORY = '/services/history'
export const SERVICESPAUSE = '/services/block'
export const SERVICECHANGETARIFF = '/services/change'
export const SERVICEUNBLOCK = '/services/unblock'
export const SERVICEAVAILABLENEWPLAN = '/services/available-new'
export const SERVICECONNECTNEW = '/services/connect'
export const SERVICEAVAILABLEOPTIONS = '/services/available-options'
export const SERVICEAVAILABLE = '/services/available'
export const SERVICE_SPEC = '/services/spec'

//services/internet
export const INTERNETSETTINGS = '/services/internet/settings'
export const INTERNETOPTIONS = '/services/internet/options'
//services/internet

//services/tv
export const TVSETTINGS = '/services/tv/settings'
export const TVEXPENSES = '/services/tv/expenses'

//services/tv

//services/telephony
export const TELEPHONYSETTINGS = '/services/telephony/settings'

//services/video/
export const VIDEOSETTINGS = '/services/video/settings'
//services/video/

// partners
export const PARTNERS = '/partners'
export const PARTNERSFORM = '/partners/form'

export const INFO = '/helpful-information'
export const COSTCONTROL = '/cost-control'

export const NEWS = '/news'
export const NEWSDETAIL = '/news/detail'
export const NOTIFICATION = '/notification'
// partners

//payment
export const PAYMENT = '/payment'
export const PAYMENTCONNECTAUTO = '/payment/connect'
export const PAYMENTEDITAUTO = '/payment/edit'
export const PAYMENTCLOSE = '/payment/payment-error'
//payment

//conditions
export const CONDITIONSPAUSE = '/conditions-pause'
export const CONDITIONSAUTOPAYMENT = '/conditions-autopayment'
//
export const PROFILE = '/profile'
export const PROFILESUBSCRIPTIONSPAGE = '/profile/subscription'
export const PROFILEDOCUMENTS = '/profile/documents'
//udomofon

export const UDOMOFONSETTINGS = '/profile/udomofon/settings'

//conditions page
export const CONDITIONSPAGE = '/conditions'
//phoneDetails
export const PHONEDETAIL = '/cost-control/phone_calls'
//radio
export const RADIOSETTINGS = '/radio'

export const PAGE404 = './404'
