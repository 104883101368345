import React from 'react'

import { Header } from 'src/components/common/header/Header'

import { AppRouter } from '../appRouter/AppRouter'

const ConditionsLayout = () => {
	return (
		<div>
			<div className="wrapper">
				<div className="header-wrapper">
					<Header />
				</div>
				<div className="main">
					<AppRouter />
				</div>
			</div>
		</div>
	)
}

export default ConditionsLayout
