import { FC } from 'react'

import Button from 'src/components/ui/button/Button'
import CopyButton from 'src/components/ui/copyButton/CopyButton'
import Svg from 'src/components/ui/svg/Svg'
import useModal from 'src/hooks/useModal'
import { SERVICECHANGETARIFF, SERVICESPAUSE, SERVICEUNBLOCK } from 'src/routes/data'
import { ServiceEthernet } from 'src/types/swagger'
import { paymentDisplay } from 'src/utils.js/paymentDisplay'

import ServiceEquipment from '../../../components/serviceEquipment/ServiceEquipment'
import ServiceInfoBlock from '../../../components/serviceInfoBlock/ServiceInfoBlock'
import ServiceSchedule from '../../../components/serviceSchedule/ServiceSchedule'
import ServiceStatus from '../../../components/ui/status/ServiceStatus'
import InternetIp from '../components/InternetIp/InternetIp'

interface IProps {
	data: ServiceEthernet
}
const InternetEthernet: FC<IProps> = ({ data }) => {
	const { open } = useModal()

	return (
		data && (
			<div className="services-page__inner">
				<div className="services-page__block active">
					<div className="connected-page__container">
						{/* //Шильдики только телефон */}
						{data.additional_buttons && (
							<div className="connected-page__button">
								{data.additional_buttons.map(button => (
									<a
										className="button button--outline button--small"
										href={button.data}
										key={button.caption}
										target="blank"
									>
										{button.caption}
									</a>
								))}
							</div>
						)}
						{/* //Шильдики только телефон */}

						<div className="connected-page__top">
							<div className="connected-page__top-col">
								<div className="connected-page__top-inner">
									<div className="connected-page__top-item">
										<div className="connected-page__icon">
											<Svg type={'eth'} />
										</div>
									</div>
									{/* //Шильдики */}
									{data.additional_buttons &&
										process.env.REACT_APP_ENV !== 'production' && (
											<div className="connected-page__top-item">
												<div className="connected-page__btn big">
													{data.additional_buttons.map(button => (
														<a
															className="button button--outline button--small"
															href={button.data}
															key={button.caption}
															target="blank"
														>
															{button.caption}
														</a>
													))}
												</div>
											</div>
										)}
									{data.additional_buttons &&
										process.env.REACT_APP_ENV === 'production' && (
											<div className="connected-page__top-item">
												<div className="connected-page__btn big">
													{data.additional_buttons.map(button => (
														<a
															className="button button--outline button--small"
															key={button.caption}
															target="blank"
															onClick={() =>
																open({
																	text: 'Этот раздел находится в разработке.',
																	modalType: null,
																	description:
																		'Попробуйте позже или обратитесь в контактный центр по номеру: 8 (812) 595-81-21'
																})
															}
														>
															{button.caption}
														</a>
													))}
												</div>
											</div>
										)}
									{/* //Шильдики */}
								</div>
							</div>
							<div className="connected-page__top-col tooltip">
								<CopyButton text={data.service_agr} />
								<div className="connected-page__top-label">
									<ServiceStatus status={data.service_status} />
								</div>
							</div>
						</div>
						<div className="connected-page__head">
							<div className="connected-page__head-col">
								<h3 className="section__title">{data.service_title}</h3>
							</div>
							{data?.plan?.plan_descr && (
								<div className="connected-page__head-col">
									<div className="section__label default">
										{data.plan.plan_descr}
									</div>
								</div>
							)}
						</div>
						<div className="connected-page__layout">
							<div className="connected-page__inner">
								<div className="connected-page__row">
									<div className="connected-page__row-col">
										<div className="services-page__card-label">Тариф</div>
										<div className="connected-page__heading underline">
											{data?.plan?.plan_name}
										</div>
									</div>
									<div className="connected-page__row-col">
										{data?.plan && data.plan.plan_fee !== null && (
											<div className="connected-page__row-item">
												<div className="services-page__card-label">
													Стоимость
												</div>
												<div className="connected-page__text">
													{paymentDisplay(
														data.plan.plan_fee,
														data.plan.fee_period,
														data.plan.fee_kind
													)}
												</div>
											</div>
										)}

										{/* //Только на мобиле */}
										{data.additional_info && (
											<div className="connected-page__row-item only-mobile">
												<div className="section__label default">
													{data.additional_info}
												</div>
											</div>
										)}
										{/* //Только на мобиле */}

										{data?.plan?.end_date && (
											<div className="connected-page__row-item">
												<div className="services-page__card-label">
													Дата окончания
												</div>
												<div className="connected-page__text">
													{data.plan.end_date}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						{/* //Оборудование, планировщик, инфоблок о планировке */}
						{data.equipment && (
							<ServiceEquipment
								data={data?.equipment}
								service_cid={data.service_cid}
							/>
						)}
						{data.schedule && <ServiceSchedule data={data.schedule} />}
						{data.info_block && <ServiceInfoBlock data={data.info_block} />}
						{/* //Оборудование, планировщик, инфоблок о планировке */}

						{/* //Блок Ip */}
						{data.external_ip && (
							<InternetIp data={data.external_ip} service_cid={data.service_cid} />
						)}
						{/* //Блок Ip */}

						{/* //Кнопки Приостановить возобновить сменить тариф и общая стоимость */}
						<div className="connected-page__inner">
							<div className="connected-page__bot">
								<div className="connected-page__bot-col">
									{data.show_plan_change_btn && (
										<div className="connected-page__bot-item">
											<div className="connected-page__btn">
												<Button
													text="Сменить тариф"
													size="small"
													theme="outline"
													href={
														SERVICECHANGETARIFF + '/' + data.service_cid
													}
												/>
											</div>
										</div>
									)}
									{data.show_block_btn && (
										<div className="connected-page__bot-item">
											<div className="connected-page__btn">
												<Button
													text="Приостановить"
													theme="outline"
													size="small"
													href={SERVICESPAUSE + '/' + data.service_cid}
												/>
											</div>
										</div>
									)}

									{data.show_unblock_btn && (
										<div className="connected-page__bot-item">
											<div className="connected-page__btn">
												<Button
													text="Возобновить"
													theme="outline"
													href={SERVICEUNBLOCK + '/' + data.service_cid}
													size="small"
												/>
											</div>
										</div>
									)}
								</div>

								{data.total_cost && (
									<div className="connected-page__bot-col">
										<div className="services-page__card-label">
											Итоговая стоимость
										</div>
										<div className="connected-page__heading">
											{paymentDisplay(
												data.total_cost.cost,
												data.total_cost.fee_period,
												data.total_cost.fee_kind
											)}
										</div>
									</div>
								)}
							</div>
						</div>
						{/* //Кнопки Приостановить возобновить сменить тариф и общая стоимость */}
					</div>
				</div>
			</div>
		)
	)
}

export default InternetEthernet
