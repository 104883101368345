import { useKeyStroke } from '@react-hooks-library/core'

import React, { FC, useEffect, useRef } from 'react'

import { CSSTransition } from 'react-transition-group'

import './SIdebarRight.scss'

interface IProps {
	state: boolean
	setState: (prev: any) => void
	children: React.ReactNode
	overlayOff?: boolean
	isLeft?: boolean
}

const SidebarRight: FC<IProps> = ({ state, setState, children, overlayOff, isLeft }) => {
	const nodeRef = useRef(null)

	// useKeyStroke('Shift', () => console.log('pressed Shift'), {
	//   // eventName: 'keypress',
	//   // target: document,
	//   // passive: false,
	// });

	return (
		<CSSTransition
			nodeRef={nodeRef}
			classNames="active"
			in={state}
			mountOnEnter
			unmountOnExit
			timeout={600}
		>
			<div className={isLeft ? 'sidebar-right sidebar-left' : 'sidebar-right'} ref={nodeRef}>
				<div
					className={`overlay ${overlayOff ? 'overlayOff' : ''}`}
					onClick={() => setState((prev: boolean) => !prev)}
				/>

				<div className="content">{children}</div>
			</div>
		</CSSTransition>
	)
}

export default SidebarRight
