import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import Button from 'src/components/ui/button/Button'
import CalendarUi from 'src/components/ui/calendar/CalenedarUi'
import FileUploadUi from 'src/components/ui/fileUpload/FileUploadUi'
import Input from 'src/components/ui/input/Input'
import Loader from 'src/components/ui/loader/Loader'
import SelectUi, { ISelectOptions } from 'src/components/ui/select/SelectUi'
import useModal from 'src/hooks/useModal'
import { PAYMENT } from 'src/routes/data'
import { PaymentError, ResponsePaymentError } from 'src/types/swagger'
import { formatDate } from 'src/utils.js/formatDate'

import Panel from '../../panel/Panel'

import './PaymentError.scss'

type FormValues = {
	payment_gate_name: string
	aggr: number
	sum: string
}

//TODO: ждем тултип

const PaymentErrorR: FC = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		getValues
	} = useForm<FormValues>({ mode: 'onChange' })

	const [date, setDate] = useState(new Date())
	const [data, setData] = useState<PaymentError | null>(null)
	const [file, setFile] = useState<File>()

	const { open } = useModal()

	const [isLoading, setIsLoading] = useState(false)
	const [paymentList, setPaymentList] = useState<ISelectOptions[] | null | undefined>(null)
	const [currentBank, setCurrentBank] = useState<string>('')

	//Запрос данных
	const fetchData = async () => {
		setIsLoading(true)
		try {
			const response: AxiosResponse<ResponsePaymentError> = await $api.get(
				`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/payments/errors/`
			)

			const { data } = response.data

			if (data) {
				setData(data)
			}
		} catch (error: any) {
			console.error('Failed to fetch payment:', error.message)
		} finally {
			setIsLoading(false)
		}
	}
	//Запрос данных

	//Первичная загрузка
	useEffect(() => {
		fetchData()
	}, [])
	//Первичная загрузка

	//Обработка значений в селект
	useEffect(() => {
		if (data) {
			const paymentGateList = data?.payment_gate?.map(item => {
				return {
					value: item.name,
					label: item.name
				}
			})
			setPaymentList(paymentGateList)

			setCurrentBank(data?.payment_gate?.[0].name)
		}
	}, [data])
	//Обработка значений в селект

	//Отправка формы

	const onSubmit: SubmitHandler<FormValues> = formData1 => {
		const fetchData = async () => {
			setIsLoading(true)

			const formData = new FormData()

			formData.append('connection_address', data!.connection_address)
			formData.append('full_name', data!.full_name!)
			formData.append('contact_phone', data!.contact_phone)
			formData.append('error_agreement', formData1.aggr.toString())
			formData.append('amount', formData1.sum)
			formData.append('payment_date', formatDate(date, 'send')!)
			formData.append('payment_gate_name', currentBank)
			if (file) {
				formData.append('file', file)
			}

			try {
				const response = await $api.post(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/payments/errors/`,
					formData
				)

				open({
					text:
						response?.data?.data?.title ||
						response?.data?.client_message ||
						'Изменения сохранены',
					modalType: 'success',
					isReload: true,
					description: response?.data?.data?.description || ''
				})
			} catch (error: any) {
				open({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	//Отправка формы

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		data && (
			<div className="services-page connected-page connected-page--default">
				<Panel
					text={'Заявление об ошибочном платеже'}
					href={PAYMENT + '/balance'}
					linkText={'Оплата'}
				/>

				<section className="section section--first-panel r-payment-error">
					<div className="container">
						<div className="section__container">
							<div className="services-page__delay">
								<h1 className="section__title">Заявление об ошибочном платеже</h1>
								<div className="connected-page__inner">
									<div className="connected-page__accordion-heading">
										Данные абонента
									</div>
									<div className="connected-page__inner">
										<div className="services-page__delay-wrapper">
											<div className="services-page__delay-inner">
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														ФИО
													</div>
													<div className="connected-page__text">
														{data.full_name}
													</div>
												</div>
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														Номер договора
													</div>
													<div className="connected-page__text">
														{data.agreement}
													</div>
												</div>
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														Номер телефона
													</div>
													<div className="connected-page__text">
														{data.contact_phone}
													</div>
												</div>
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														Адрес подключения
													</div>
													<div className="connected-page__text">
														{data.connection_address}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<form
									className="connected-page__inner react-close-payment"
									onSubmit={handleSubmit(onSubmit)}
								>
									<div className="connected-page__accordion-heading">
										Данные ошибочного платежа
									</div>
									<div className="services-page__inner">
										<div className="services-page__delay-wrapper">
											<div className="services-page__delay-grid">
												<div className="services-page__delay-item">
													<div className="services-page__delay-tooltip tooltip tooltip--alt">
														<div className="section__label default">
															Дата запуска
														</div>
													</div>
													<div className="r-payment-error__calendar">
														<CalendarUi
															value={date}
															setValue={setDate}
															svg={true}
															// onClick={handleShow}
															isButtonVisible={false}
														/>
													</div>
												</div>

												{/* //Платежная система */}
												<div className="services-page__delay-item">
													<div className="section__label">
														Платежная система
													</div>

													<div className="react-payment-error__select">
														{paymentList && (
															<SelectUi
																//@ts-ignore
																setValue={setCurrentBank}
																optionsValue={paymentList}
																name="payment_gate_name"
																options={{
																	required: 'Заполните поле'
																}}
															/>
														)}
													</div>
												</div>
												{/* //Платежная система */}

												<div className="services-page__delay-item">
													<div className="section__label">
														Ошибочный номер договора
													</div>
													<Input
														register={register}
														filled={!!watch('aggr')}
														name="aggr"
														error={errors.aggr}
														options={{
															required: 'Заполните поле',
															pattern: {
																value: /^\d+$/,
																message: 'Неверный формат'
															}
														}}
													/>
												</div>
												<div className="services-page__delay-item">
													<div className="section__label">Сумма</div>
													<Input
														register={register}
														filled={!!watch('sum')}
														name="sum"
														error={errors.sum}
														options={{
															required: 'Заполните поле',
															pattern: {
																value: /^\d+$/,
																message: 'Неверный формат'
															}
														}}
													/>
												</div>
											</div>

											{/* //инпут для загрузки файлов */}
											<div className="react-payment-error__wrapper">
												<FileUploadUi
													text="Вложить рукописное заявление"
													onChange={setFile}
													maxSizeMB={1}
													allowedTypes={[
														'text/plain',
														'application/msword',
														'application/pdf',
														'image/png',
														'image/jpeg'
													]}
												/>
											</div>
											{/* //инпут для загрузки файлов */}
										</div>
									</div>
									<div className="services-page__delay-bot">
										<div className="services-page__delay-btn">
											<Button
												text={'Отменить'}
												size={'small'}
												theme={'outline'}
												href={PAYMENT + '/balance'}
											/>
										</div>
										<div className="services-page__delay-btn">
											<Button
												text={'Отправить'}
												size={'small'}
												type={'submit'}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	)
}

export default PaymentErrorR
