import { FC, useEffect, useState } from 'react'

import Button from 'src/components/ui/button/Button'
import CheckboxDefault from 'src/components/ui/checkbox/CheckboxDefault'
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi'
import Modal from 'src/components/ui/modal/Modal'
import TooltipUi from 'src/components/ui/tooltip/TooltipUi'
import { sendVideoModulesChange, setFilterModules } from 'src/features/video/change-modules'
import { useAppDispatch, useAppSelector } from 'src/hooks/redusHooks'
import useModal from 'src/hooks/useModal'
import { RecAdditionalModule } from 'src/types/swagger'
import { paymentDisplay } from 'src/utils.js/paymentDisplay'

//стили этого компонента тянутся с модального с тв
interface IProps {
	state: boolean
	setState: (prev: any) => void
}

const VideoChangeModules: FC<IProps> = ({ state, setState }) => {
	const {
		filterModules,
		modules: data,
		sum,
		condition_block,
		service_cid
	} = useAppSelector(state => state.videoChangeModulesSlice)
	const dispatch = useAppDispatch()

	//Лоадер
	const [isLoadingButton, setIsLoadingButton] = useState(false)
	const [isPacketModalActive, setPacketModalActive] = useState(false)

	const { open } = useModal()

	//Функция для закрытия модального окна с пакетами
	const handleExit = () => {
		setPacketModalActive(false)
		setTimeout(() => {
			setState((prev: boolean) => !prev)
		}, 600)
	}
	//Функция для закрытия модального окна с пакетами

	//Открытие модального окна при заходе на страницу
	useEffect(() => {
		if (state) {
			setPacketModalActive(true)
		}
	}, [state])
	//Открытие модального окна при заходе на страницу

	//колбек для изменения чекбокса
	const handleCheckboxChange = (item: RecAdditionalModule) => {
		dispatch(setFilterModules(item))
	}
	//колбек для изменения чекбокса

	const onSubmit = async () => {
		setIsLoadingButton(true)

		try {
			const response = await sendVideoModulesChange({
				service_cid,
				body: {
					modules:
						filterModules.length === 0
							? []
							: filterModules.map(item => {
									return {
										plan_id: item.plan_id.toString(),
										key: item.key
									}
								})
				}
			})
			//@ts-ignore
			const { data } = response
			open({
				text: data?.data?.title || data?.client_message || 'Изменения сохранены',
				modalType: 'success',
				isReload: true,
				description: data?.data?.description || ''
			})
		} catch (error: any) {
			console.info('error', error)
			open({
				text:
					error.response?.data?.data?.title ||
					error.response.data.client_message ||
					'Ошибка',
				description: error.response?.data?.data?.description || '',
				modalType: 'error'
			})
		} finally {
			setIsLoadingButton(false)
			handleExit()
		}
	}

	return (
		data && (
			<Modal state={isPacketModalActive} setState={handleExit}>
				<div
					className="modules-modal modal fancybox-content react-tvChangePacket"
					data-fancy-modal="modules"
					data-tv-modal=""
					style={{ display: 'inline-block' }}
				>
					<div className="modules-modal__container">
						<div className="modules-modal__title">Дополнительные модули</div>
						<div className="modules-modal__grid">
							{data.map(item => (
								<div className="modules-modal__item" key={item.key}>
									<div className="modules-modal__top">
										<CheckboxDefault
											text={item.module_name}
											defaultValue={filterModules?.includes(item)}
											// disabled={!item.}
											callback={() => handleCheckboxChange(item)}
										/>
									</div>
									<div className="modules-modal__inner">
										{item.plan_fee !== null && (
											<div className="modules-modal__price">
												<div className="services-page__card-label">
													Стоимость
												</div>
												<div className="connected-page__text">
													{paymentDisplay(
														item.plan_fee,
														item.fee_period,
														item.fee_kind
													)}
												</div>
											</div>
										)}
										{item.module_description && (
											<div className="modules-modal__wrapper">
												<TooltipUi
													text={item.module_description}
													type={'more'}
												/>
											</div>
										)}
									</div>
								</div>
							))}
						</div>
						<div className="modules-modal__bot">
							<div className="connected-page__bot">
								{/* //buttons */}
								<div className="connected-page__bot-col">
									<div className="connected-page__bot-item">
										<div className="connected-page__btn">
											<Button
												text={'Сохранить'}
												size={'small'}
												isLoading={isLoadingButton}
												onClick={onSubmit}
											/>
										</div>
									</div>
									<div className="connected-page__bot-item">
										<div className="connected-page__btn">
											<Button
												text={'Отменить'}
												theme={'outline'}
												size={'small'}
												onClick={handleExit}
											/>
										</div>
									</div>
								</div>
								{/* //buttons */}

								<div className="connected-page__bot-col">
									<div className="services-page__card-label">
										Итоговая стоимость
									</div>

									<div className="connected-page__heading">{sum} ₽/мес</div>
								</div>
							</div>
						</div>

						{/* //ссылка на условия  */}
						<ConditionUi data={condition_block} />
						{/* //ссылка на условия  */}
					</div>
					<div className="modal__icon" data-fancybox-close="" onClick={handleExit}>
						<svg
							className="modal__svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
								fill="#001F38"
							/>
						</svg>
					</div>
					<button
						type="button"
						data-fancybox-close=""
						className="fancybox-button fancybox-close-small"
						title="Close"
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
						</svg>
					</button>
				</div>
			</Modal>
		)
	)
}

export default VideoChangeModules
