import React from 'react'

import Button from 'src/components/ui/button/Button'
import { PARTNERSFORM } from 'src/routes/data'

import Panel from '../panel/Panel'

const Partners = () => {
	return (
		<div className="partners-page">
			<Panel text="Партнерская программа" />
			<section className="section partners-page__intro">
				<div className="container">
					<div className="partners-page__container">
						<div className="partners-page__top">
							<h1 className="partners-page__title">Партнерская программа</h1>
							<div className="partners-page__text">
								Отличный способ пополнить свой счет без лишних затрат!
							</div>
						</div>
						<div className="partners-page__steps">
							<div className="partners-page__heading">Для этого:</div>
							<div className="partners-page__steps-inner">
								<div className="partners-page__steps-item">
									<div className="partners-page__steps-num">1</div>
									<div className="partners-page__steps-text">
										Расскажите Другу про "ПАКТ"
									</div>
								</div>
								<div className="partners-page__steps-item">
									<div className="partners-page__steps-num">2</div>
									<div className="partners-page__steps-text">
										Уточните у Друга номер договора после подключения
									</div>
								</div>
								<div className="partners-page__steps-item">
									<div className="partners-page__steps-num">3</div>
									<div className="partners-page__steps-text">
										Заполните форму в срок не позднее 15 дней с момента
										подключения.
									</div>
								</div>
							</div>
							<div className="partners-page__steps-caption">
								После активации лицевого счета Вашего Друга, на Ваш счет будут
								перечислены 500 рублей!
							</div>
						</div>
						<div className="partners-page__block">
							<div className="partners-page__heading">Обращаем внимание!</div>
							<ul className="partners-page__list">
								<li className="partners-page__list-item">
									Акция действует только при заключении индивидуального договора
									на услуги "ПАКТ";
								</li>
								<li className="partners-page__list-item">
									Подарочная сумма в размере 500 рублей не зависит от количества
									подключенных услуг;
								</li>
								<li className="partners-page__list-item">
									Одного Друга можно подключить только один раз. Количество друзей
									неограниченно!
								</li>
								<li className="partners-page__list-item">
									В случае если Ваш Друг отказался от услуг в течения 3 месяцев
									после подписания договора, Ваша подарочная сумма аннулируется!
								</li>
								<li className="partners-page__list-item">
									Перечисленные бонусы Вы можете использовать на оплату любых
									Ваших услуг.
								</li>
							</ul>
						</div>
						<div className="partners-page__block">
							<div className="partners-page__button">
								<Button text="Участвовать" href={PARTNERSFORM} />
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Partners
