import { IModalData, closeModal, openModal } from 'src/redux/slices/modalSlice'

import { useAppDispatch } from './redusHooks'

const useModal = () => {
	const dispatch = useAppDispatch()

	const open = (modalData: IModalData) => {
		dispatch(openModal(modalData))
	}

	const close = () => {
		dispatch(closeModal())
	}

	return { open, close }
}

export default useModal
