import { combineReducers } from '@reduxjs/toolkit'

import serviceChangeSlice from 'src/features/service-change/model/serviceChangeSlice'
import videoChangeModulesSlice from 'src/features/video/change-modules/model/videoChangeModulesSlice'
import appSlice from 'src/redux/slices/appSlice'
import clientSlice from 'src/redux/slices/clientSlice'
import conditionSlice from 'src/redux/slices/conditionSlice'
import modalSlice from 'src/redux/slices/modalSlice'
import newsSlice from 'src/redux/slices/newsSlice'
import notificationsSlice from 'src/redux/slices/notificationsSlice'

const appReducer = combineReducers({
	modal: modalSlice,
	app: appSlice,
	client: clientSlice,
	condition: conditionSlice,
	news: newsSlice,
	notifications: notificationsSlice,
	serviceChange: serviceChangeSlice,
	videoChangeModulesSlice: videoChangeModulesSlice
})

const rootReducer = (state, action) => {
	if (action.type === 'RESET') {
		state = undefined
	}
	return appReducer(state, action)
}

export default rootReducer
