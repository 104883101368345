import React, { FC, useState } from 'react'

import { AxiosResponse } from 'axios'
import { API_DOMAIN } from 'src/api/service/endpoints'
import $api from 'src/api/service/request'
import Button from 'src/components/ui/button/Button'
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi'
import { useAppDispatch } from 'src/hooks/redusHooks'
import useModal from 'src/hooks/useModal'
import { BaseResponse } from 'src/types/swagger'
import { openModalConfig } from 'src/utils.js/openModalOptions'

const Test: FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>()
	const isProduction = process.env.REACT_APP_ENV === 'production'
	const { open } = useModal()
	const fetchData = async (path: string) => {
		console.info('test ЗАПУЩЕН')
		setIsLoading(true)
		try {
			const response = await $api.get(`${API_DOMAIN}tests/${path}/`)
			console.info('response ВЫПОЛНЕН', response)
		} catch (error: any) {
			console.info('response ОШИБКА', error)
		} finally {
			setIsLoading(false)
		}
	}

	const openModalWithHtml = async (path: string) => {
		console.info('test ЗАПУЩЕН')
		setIsLoading(true)
		try {
			const response: AxiosResponse<BaseResponse> = await $api.post(
				`${API_DOMAIN}clients/66667/services/new/`,
				{
					contact_phone: '79516716362',
					service_type: 'ott'
				}
			)
			open({ ...openModalConfig(response, false) })
		} catch (error: any) {
			open({ ...openModalConfig(error.response, true) })
		} finally {
			setIsLoading(false)
		}
	}

	return (
		!isProduction && (
			<div
				style={{
					padding: 20,
					display: 'flex',
					flexDirection: 'column',
					gap: 40,
					backgroundColor: 'white'
				}}
			>
				{/* <Button text="Открыть модальное окно условий" onClick={handleOpenConditionModal} /> */}
				<div className="react-conditions-content">
					<div className="table ">
						<table>
							<tbody>
								<tr>
									<th rowSpan={3}>Технология</th>
									<th rowSpan={3}>Тарифный план</th>
									<th colSpan={4}>Стоимость тарифных планов, руб.</th>
								</tr>
								<tr>
									<td rowSpan={2}>1 месяц</td>
									<td colSpan={3}>Абонементы</td>
								</tr>
								<tr>
									<td>3 месяца</td>
									<td>6 месяцев</td>
									<td>12 месяцев</td>
								</tr>
								<tr>
									<td>DOCSIS</td>
									<td>2 Мбит/с</td>
									<td>120</td>
									<td colSpan={3}>
										&nbsp;ТП доступен при наличии тех.возможности &nbsp;
									</td>
								</tr>
								<tr>
									<td>DOCSIS</td>
									<td>6 Мбит/с</td>
									<td>200</td>
									<td colSpan={3}>
										&nbsp;ТП доступен при наличии тех.возможности &nbsp;
									</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>10 Мбит/с</td>
									<td>250</td>
									<td colSpan={3}>&nbsp;ТП закрыт для подключения &nbsp;</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>30 Мбит/с</td>
									<td>350</td>
									<td colSpan={3}>&nbsp;ТП закрыт для подключения &nbsp;</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>50 Мбит/с</td>
									<td>450</td>
									<td>1290/430</td>
									<td>2400/400</td>
									<td>4200/350</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>100 Мбит/с</td>
									<td>550</td>
									<td>1425/475</td>
									<td>2700/450</td>
									<td>4800/400</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>300 Мбит/с</td>
									<td>730</td>
									<td>1800/600</td>
									<td>3450/575</td>
									<td>6600/550</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>700 Мбит/с</td>
									<td>900</td>
									<td>2250/750</td>
									<td>4350/725</td>
									<td>8400/700</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>900 Мбит/с</td>
									<td>1300</td>
									<td>2700/900</td>
									<td>5100/850</td>
									<td>9000/750</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>Best 50 Мбит/с</td>
									<td>350</td>
									<td>&nbsp;990/330</td>
									<td>1800/300</td>
									<td>3000/250</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>Best 100 Мбит/с</td>
									<td>450</td>
									<td>1125/375</td>
									<td>2100/350</td>
									<td>3900/325</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>Best 300 Мбит/с</td>
									<td>650</td>
									<td>1725/575</td>
									<td>3300/550</td>
									<td>6000/500</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>Best 700 Мбит/с</td>
									<td>800</td>
									<td>2175/725</td>
									<td>4200/700</td>
									<td>7800/650</td>
								</tr>
								<tr>
									<td>ETHERNET/PON</td>
									<td>Best 900 Мбит/с</td>
									<td>1100</td>
									<td>2550/850</td>
									<td>4800/800</td>
									<td>8400/700</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<Button
					isLoading={isLoading}
					text="is_system_error_hidden"
					onClick={() => fetchData('is_system_error_hidden_true/')}
				/>
				<ConditionUi data={null} />
				<Button
					isLoading={isLoading}
					text="openModalWithHtml"
					onClick={openModalWithHtml}
				/>
				<Button
					isLoading={isLoading}
					text="access_restricted_before_authentication_40301"
					onClick={() => fetchData('access_restricted_before_authentication_40301/')}
				/>
				<Button
					isLoading={isLoading}
					text="access_to_lk_is_restricted_40301"
					onClick={() => fetchData('access_to_lk_is_restricted_40301/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/invalid_data_40000/"
					onClick={() => fetchData('invalid_data_40000/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/login_or_password_not_registered_40102/"
					onClick={() => fetchData('login_or_password_not_registered_40102/')}
				/>
				//login
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/authorization_server_error_50001/"
					onClick={() => fetchData('authorization_server_error_50001/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/database_tocken_error_40103/"
					onClick={() => fetchData('database_tocken_error_40103/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/error_during_token_refresh_40101/"
					onClick={() => fetchData('error_during_token_refresh_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/invalid_authorization_header_format_40101/"
					onClick={() => fetchData('invalid_authorization_header_format_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/login_does_not_match_token_payload_40101/"
					onClick={() => fetchData('login_does_not_match_token_payload_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/refresh_token_not_found_40101/"
					onClick={() => fetchData('refresh_token_not_found_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/token_creation_failed_40103/"
					onClick={() => fetchData('token_creation_failed_40103/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/token_expired_40100/"
					onClick={() => fetchData('token_expired_40100/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/token_is_blocked_40101/"
					onClick={() => fetchData('token_is_blocked_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/token_keys_are_missing_40101/"
					onClick={() => fetchData('token_keys_are_missing_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/token_payload_invalid_40101/"
					onClick={() => fetchData('token_payload_invalid_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="/api/v1/tests/token_refresh_expired_40101/"
					onClick={() => fetchData('token_refresh_expired_40101/')}
				/>
				<Button
					isLoading={isLoading}
					text="502"
					onClick={() => fetchData('bad_gateway_502/')}
				/>
				<Button
					isLoading={isLoading}
					text="404"
					onClick={() => fetchData('not_found_404/')}
				/>
				<Button
					isLoading={isLoading}
					text="500"
					onClick={() => fetchData('server_error_500/')}
				/>
			</div>
		)
	)
}

export default Test
