import React, { FC } from 'react'

import { Link, NavLink } from 'react-router-dom'
import { SERVICES, SERVICESTABAVAILABLE, SERVICESTABHISTORY, SERVICE_SPEC } from 'src/routes/data'

const ServicesTabs: FC = () => {
	return (
		<div className="tabs tabs--wide">
			<div className="tabs__list">
				<NavLink to={SERVICES} className="tabs__item" end>
					Подключенные
				</NavLink>
				<NavLink to={SERVICESTABAVAILABLE} className="tabs__item ">
					Доступные
				</NavLink>
				<NavLink to={SERVICE_SPEC} className="tabs__item">
					Специальные акции и предложения
				</NavLink>
				{process.env.REACT_APP_ENV !== 'production' && (
					<NavLink to={SERVICESTABHISTORY} className="tabs__item">
						История смены тарифов
					</NavLink>
				)}
			</div>
		</div>
	)
}

export default ServicesTabs
