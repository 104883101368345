import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { API_DOMAIN } from 'src/api/service/endpoints'
import $api from 'src/api/service/request'
import Button from 'src/components/ui/button/Button'
import CheckboxDefault from 'src/components/ui/checkbox/CheckboxDefault'
import Loader from 'src/components/ui/loader/Loader'
import Modal from 'src/components/ui/modal/Modal'
import { ResponseServiceCondition, ServiceCondition } from 'src/types/swagger'

interface IProps {
	state: boolean
	setState: (prev: any) => void
	id: string
	onSubmit: () => void
	title: string
	checkboxes: string[] | undefined | null
}

const ChangeTariffModal: FC<IProps> = ({
	state,
	setState,
	id,
	onSubmit: callback,
	title,
	checkboxes
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState<ServiceCondition>()
	const [checkboxStates, setCheckboxStates] = useState<boolean[]>([])

	useEffect(() => {
		if (checkboxes) {
			setCheckboxStates(new Array(checkboxes.length).fill(false))
		}
	}, [checkboxes, state])

	const handleCheckboxChange = (index: number) => {
		setCheckboxStates(prev => prev.map((checked, i) => (i === index ? !checked : checked)))
	}

	useEffect(() => {
		if (!id) return
		// document.body.style.overflow = 'auto';
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServiceCondition> = await $api.get(
					`${API_DOMAIN}promotions/service_conditions/${id}/`
				)

				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch video:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [id])

	const handleCloseModal = () => {
		setCheckboxStates([])
		setState((prev: any) => !prev)
	}

	const allChecked = checkboxStates.every(item => item === true)

	const onSubmit = () => {
		callback()
		handleCloseModal()
	}

	return (
		<Modal state={state} setState={handleCloseModal} callback={handleCloseModal}>
			<form className="response-modal options-modal r-condition-modal">
				<Loader state={isLoading} />

				<div className="r-condition-modal__close" onClick={handleCloseModal}>
					<svg
						className="response-modal__close-svg"
						xmlns="http://www.w3.org/2000/svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
							fill="#001F38"
						/>
					</svg>
				</div>
				<div className="r-condition-modal__header">{title && <h1>{title}</h1>}</div>
				{data && (
					<div className="r-condition-modal__content ">
						<div className="react-conditions-content">
							{data.text_type === ServiceCondition.TextTypeEnum.Plain ? (
								<p>{data.text}</p>
							) : (
								<div dangerouslySetInnerHTML={{ __html: data.text }} />
							)}
						</div>
					</div>
				)}
				<div className="r-condition-modal__footer">
					<div className="r-condition-modal__row">
						{checkboxes && (
							<div className="r-condition-modal__checkboxes">
								{checkboxes.map((item, index) => (
									<CheckboxDefault
										callback={() => {
											handleCheckboxChange(index)
										}}
										text={item}
										key={item}
										defaultValue={checkboxStates[index]}
									/>
								))}
							</div>
						)}
					</div>
					<div className="r-condition-modal__controls">
						<Button text="Отменить" theme={'outline'} onClick={handleCloseModal} />
						<Button
							text="Принять"
							onClick={onSubmit}
							disabled={!!checkboxes && !allChecked}
						/>
					</div>
				</div>
			</form>
		</Modal>
	)
}

export default ChangeTariffModal
