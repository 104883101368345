import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useForm } from 'react-hook-form'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import Button from 'src/components/ui/button/Button'
import CopyButton from 'src/components/ui/copyButton/CopyButton'
import Input from 'src/components/ui/input/Input'
import Loader from 'src/components/ui/loader/Loader'
import useModal from 'src/hooks/useModal'
import { ReceiptConfirm, ResponseReceiptConfirm } from 'src/types/swagger'

import './PaymentReciepts.scss'

interface IProps {
	agreement: number
}

const PaymentReciepts: FC<IProps> = ({ agreement }) => {
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		formState: { errors }
	} = useForm({
		defaultValues: {
			amount: 10
		}
	})

	const { open } = useModal()

	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState<ReceiptConfirm>()
	//Первичный запрос
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseReceiptConfirm> = await $api.get(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/receipt/`
				)
				const { data } = response.data

				if (data) {
					setData(data)
					setValue('amount', data.deposit_amount)
				}
			} catch (error: any) {
				console.error('Failed to fetch client:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичный запрос

	const onSubmit = () => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/receipt/`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${getFromLocalStorage('token')}`
						},
						body: JSON.stringify({ amount: getValues('amount').toString() })
					}
				)

				if (response.ok) {
					const blob = await response.blob()
					const contentDisposition =
						response.headers.get('Content-Disposition') ||
						'attachment; filename=invoice.pdf'

					// Предполагаем, что имя файла может быть извлечено из contentDisposition, если оно там есть.
					const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
						contentDisposition
					)
					const filename = (filenameMatch && filenameMatch[1]) || 'invoice.pdf'

					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						// Для IE
						window.navigator.msSaveOrOpenBlob(blob, filename)
					} else {
						// Для других браузеров
						const url = window.URL.createObjectURL(blob)
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', filename)
						document.body.appendChild(link)
						link.click()

						window.URL.revokeObjectURL(url)
						link.remove()
					}
				} else {
					console.error('Не удалось получить PDF1:', response.statusText)
				}
			} catch (error) {
				console.error('Ошибка при запросе PDF2:', error)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}

	if (isLoading) return <Loader state={isLoading} />

	return (
		data && (
			<form className="payment-page__row" onSubmit={handleSubmit(onSubmit)}>
				<div className="payment-page__col">
					<div className="section__container payment-page__column react-reciepts">
						<div className="payment-page__wrapper payment-page__column-wrapper">
							<div className="payment-page__column-top">
								<h2 className="section__title">{data.title}</h2>
								<div className="react-reciepts__caption">{data.description}</div>
								<div className="payment-page__refill">
									<div className="payment-page__refill-row">
										<div className="payment-page__refill-col">
											<div className="react-reciepts__item">
												<div className="payment-page__label">
													{data.balance_description}
												</div>
												<div className="payment-page__text">
													{data.balance}
												</div>
											</div>
											<div className="react-reciepts__item">
												<div className="payment-page__label">
													{data.cost_description}
												</div>
												<div className="payment-page__text">
													{data.cost}
												</div>
											</div>
											<div className="react-reciepts__item">
												<div className="payment-page__label">
													{data.recommend_pay_description}
												</div>
												<div className="payment-page__text">
													{data.recommend_pay}
												</div>
											</div>
										</div>
										<div className="payment-page__refill-col">
											<div className="payment-page__label">
												{data.agreement_description}
											</div>
											<CopyButton
												text={data.agreement}
												size={true}
												variant={2}
											/>
										</div>
									</div>
								</div>
								<div
									className="react-reciepts__bot"
									dangerouslySetInnerHTML={{ __html: data.additional_text }}
								></div>
							</div>
							<div className="payment-page__column-bot">
								<div className="react-pay-item"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="payment-page__col">
					<div className="section__container payment-page__column">
						<div className="payment-page__pay">
							<Input
								placeholder="Сумма пополнения"
								register={register}
								filled={!!watch('amount')}
								name="amount"
								error={errors.amount}
								type="number"
								validation={{
									required: 'Заполните поле',
									pattern: {
										value: /^\d+$/,
										message: 'Неверный формат'
									},
									min: {
										value: 10,
										message: 'Минимальная сумма 10 рублей'
									},
									max: {
										value: 30000,
										message: 'Максимальная сумма 30 000 рублей'
									}
								}}
							/>
							<div className="payment-page__pay-offset">
								<div className="payment-page__pay-row">
									<div className="payment-page__pay-heading">Итого к оплате:</div>
									<div className="payment-page__pay-price">
										{watch('amount') + ' ₽'}
									</div>
								</div>
							</div>
							<div className="payment-page__pay-btn">
								<Button text={'Запросить квитанцию'} type="submit" />
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	)
}

export default PaymentReciepts
