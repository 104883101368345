import { FC } from 'react'

import './InputError.scss'

interface IProps {
	text: string
}
const InputError: FC<IProps> = ({ text }) => {
	return <div className="input-error">{text}</div>
}

export default InputError
