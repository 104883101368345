import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { parse } from 'date-fns'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import Accordion from 'src/components/ui/accordion/Accordion'
import Button from 'src/components/ui/button/Button'
import CalendarRange from 'src/components/ui/calendar2/CalendarRangeUi'
import CopyButton from 'src/components/ui/copyButton/CopyButton'
import Loader from 'src/components/ui/loader/Loader'
import { PHONEDETAIL } from 'src/routes/data'
import { Charge, Payment, ReportsOverview, ResponseReportsOverview } from 'src/types/swagger'
import { formatDate } from 'src/utils.js/formatDate'

import Panel from '../panel/Panel'

import './CostsControl.scss'

const CostsControl: FC = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState<ReportsOverview | null>(null)
	const [value, setValue] = useState<DateRange>()
	const [chargesList, setChargesList] = useState<Charge[] | null>(null)
	const [visibleCharges, setVisibleCharges] = useState<Charge[] | null>([])

	const [paymentsList, setPaymentsList] = useState<Payment[] | null>([])
	const [visiblePayments, setVisiblePayments] = useState<Payment[] | null>([])

	//Запрос данных
	const fetchData = async (date?: DateRange | undefined) => {
		setIsLoading(true)

		try {
			const response: AxiosResponse<ResponseReportsOverview> = await $api.get(
				`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/reports/${
					date
						? `?start_date=${formatDate(date[0], 'send')}&` +
							`end_date=${formatDate(date[1], 'send')}`
						: ''
				}`
			)

			const { data } = response.data

			if (data) {
				setData(data)

				//Установление начислений
				if (data?.charges?.charges) {
					setChargesList(data.charges.charges)
				}
				//Установление начислений

				//Установление Платежей
				if (data.payments?.payments) {
					setPaymentsList(data.payments.payments)
				}
				//Установление Платежей

				//установка значения периода при загурзке
				setValue([
					parse(data.start_date, 'dd.MM.yyyy', new Date()),
					parse(data.end_date, 'dd.MM.yyyy', new Date())
				])
				//установка значения периода при загурзке
			}
		} catch (error: any) {
			console.error('Failed to fetch cost-control:', error)
		} finally {
			setIsLoading(false)
		}
	}

	//Запрос данных

	//Первичная загрузка
	useEffect(() => {
		fetchData()
	}, [])
	//Первичная загрузка

	//Разделение списка
	useEffect(() => {
		if (chargesList) {
			setVisibleCharges(chargesList.slice(0, 7))
		}

		if (paymentsList) {
			setVisiblePayments(paymentsList.slice(0, 7))
		}
	}, [chargesList, paymentsList])
	//Разделение списка

	//Показ всего списка
	const handleShowAllClicks = (type: 'charges' | 'payments') => {
		if (type === 'charges') {
			setVisibleCharges(chargesList)
		} else {
			setVisiblePayments(paymentsList)
		}
	}
	//Показ всего списка

	//Лоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}
	//Лоадер

	return (
		data &&
		visibleCharges && (
			<div className="costs-page r-costs-page">
				<Panel text="Контроль расходов" />
				<section className="section section--first-panel r-section-first">
					<div className="container">
						<div className="section__container">
							<div className="costs-page__wrapper">
								<div className="costs-page__top">
									<h1 className="section__title">Контроль расходов</h1>

									<div className="costs-page__copy">
										<CopyButton text={data.agreement} size={true} />
									</div>
								</div>
								<div className="costs-page__inner">
									<div className="costs-page__inner-col">
										<div className="costs-page__calendar">
											<CalendarRange
												value={value}
												setValue={setValue}
												disable="after"
												//@ts-ignore
												callback={fetchData}
											/>
										</div>
										<div className="costs-page__balance">
											<div className="costs-page__balance-text">
												Баланс на {formatDate(new Date(), 'date')}:
											</div>
											{data.balance && (
												<div className="costs-page__price">
													{data.balance} ₽
												</div>
											)}
										</div>
									</div>
									<div className="costs-page__inner-col">
										<Button
											text={'Детализация звонков'}
											theme="outline"
											href={PHONEDETAIL}
										/>
									</div>
								</div>

								<div className="costs-page__accordions">
									{/* //Расходы */}
									<Accordion title={'Начисления'} active={true}>
										<div className="costs-page__accordions-inner">
											<div className="costs-page__accordions-col">
												<div className="costs-page__price">
													Баланс на {data?.charges?.start_date}
												</div>
											</div>
											<div className="costs-page__accordions-col">
												<div className="costs-page__price">
													{data?.charges?.start_balance} ₽
												</div>
											</div>
										</div>
										<div className="costs-page__accordions-table">
											<div className="costs-page__accordions-top">
												<div className="costs-page__accordions-grid">
													<div className="costs-page__accordions-item">
														<div className="section__label default">
															Дата
														</div>
													</div>
													<div className="costs-page__accordions-item">
														<div className="section__label default">
															Услуга/Тариф
														</div>
													</div>
													<div className="costs-page__accordions-item">
														<div className="section__label default">
															Сумма начисления
														</div>
													</div>
													<div className="costs-page__accordions-item">
														<div className="section__label default">
															Сумма платежа
														</div>
													</div>
												</div>
											</div>
											<div className="costs-page__accordions-body">
												{visibleCharges.map((item, index) => (
													<div
														className="costs-page__accordions-grid"
														key={index}
													>
														<div className="costs-page__accordions-item">
															<div className="section__label only-mobile">
																Дата
															</div>
															<div className="costs-page__text">
																{item.date}
															</div>
														</div>
														<div className="costs-page__accordions-item">
															<div className="section__label only-mobile">
																Услуга/Тариф
															</div>
															<div className="costs-page__text">
																{item.name}
															</div>
														</div>
														<div className="costs-page__accordions-item">
															<div className="section__label only-mobile">
																Сумма начисления
															</div>
															{item.amount_charge && (
																<div className="costs-page__text">
																	{item.amount_charge} ₽
																</div>
															)}
														</div>

														<div className="costs-page__accordions-item">
															{item.amount_payment && (
																<div className="costs-page__text">
																	{item.amount_payment} ₽
																</div>
															)}
														</div>
													</div>
												))}
											</div>
										</div>

										{visibleCharges.length === 7 && (
											<div
												className="costs-page__accordions-btn"
												onClick={() => handleShowAllClicks('charges')}
											>
												Показать все
											</div>
										)}

										{data?.charges?.end_date && (
											<div className="costs-page__accordions-inner">
												<div className="costs-page__accordions-col">
													<div className="costs-page__price react-costs-page__price">
														Баланс на {data.charges.end_date}
													</div>
												</div>
												<div className="costs-page__accordions-col">
													<div className="costs-page__price">
														{data.charges.end_balance} ₽
													</div>
												</div>
											</div>
										)}
									</Accordion>
									{/* //Расходы */}

									{/* //Платежи */}
									<Accordion
										active={true}
										price={data?.payments?.total_amount}
										title={'Платежи'}
									>
										<div className="costs-page__accordions-top">
											<div className="costs-page__accordions-grid alt">
												<div className="costs-page__accordions-item">
													<div className="section__label default">
														Дата
													</div>
												</div>
												<div className="costs-page__accordions-item">
													<div className="section__label default">
														Тип платежа
													</div>
												</div>
												<div className="costs-page__accordions-item">
													<div className="section__label default">
														Сумма
													</div>
												</div>
											</div>
										</div>
										<div className="costs-page__accordions-body">
											{visiblePayments &&
												visiblePayments.map((item, index) => (
													<div
														className="costs-page__accordions-grid alt"
														key={index}
													>
														<div className="costs-page__accordions-item">
															<div className="section__label only-mobile">
																Дата
															</div>
															<div className="costs-page__text">
																{item.date}
															</div>
														</div>
														<div className="costs-page__accordions-item">
															<div className="section__label only-mobile">
																Тип платежа
															</div>
															<div className="costs-page__text">
																{item.name}
															</div>
														</div>
														<div className="costs-page__accordions-item">
															<div className="section__label only-mobile">
																Сумма
															</div>
															<div className="costs-page__text">
																{item.amount} ₽
															</div>
														</div>
													</div>
												))}

											{visiblePayments && visiblePayments.length === 7 && (
												<div
													className="costs-page__accordions-btn"
													onClick={() => handleShowAllClicks('payments')}
												>
													Показать все
												</div>
											)}
										</div>
									</Accordion>
									{/* //Платежи */}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	)
}

export default CostsControl
