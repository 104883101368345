import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import Panel from 'src/components/common/panel/Panel'
import Loader from 'src/components/ui/loader/Loader'
import useModal from 'src/hooks/useModal'
import { SERVICES, SERVICESTABAVAILABLE, SERVICESTABHISTORY } from 'src/routes/data'
import { AvailableService, ResponseAvailableServicesList } from 'src/types/swagger'

import AvailableCard from '../../components/availableCard/AvailableCard'
import ServicesTabs from '../components/ServicesTabs'

const ServiceTabAvailable: FC = () => {
	const [availableCardList, setAvailableCardList] = useState<AvailableService[] | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const { open } = useModal()
	//Первичные данные
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseAvailableServicesList> = await $api.get(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/services/available/`
				)
				const { data } = response.data

				if (data) {
					//Сортировка
					const sortedList = data.available_services.sort((a, b) => {
						return a.order - b.order
					})
					//Сортировка

					setAvailableCardList(sortedList)
				}
			} catch (error: any) {
				console.error('Failed to fetch client:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичные данные

	//Лоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}
	//Лоадер

	return (
		<div className="services-page">
			<Panel text="Услуги" />
			<section className="section section--first-panel r-section-first">
				<div className="container">
					<ServicesTabs />
					<div className="services-page__inner">
						{availableCardList && (
							<div className="services-page__block active">
								<div className="service-grid">
									{availableCardList.map((card, index) => (
										<AvailableCard data={card} key={index} />
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
		</div>
	)
}

export default ServiceTabAvailable
