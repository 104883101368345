import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Condition } from 'src/types/Condition'
import { RecAdditionalModule } from 'src/types/swagger'

import { videoChangeModulesInitial } from './initial'

const calculateSum = (modules: RecAdditionalModule[]) => {
	return modules.reduce((acc: number, item) => {
		if (item.plan_fee !== null && item.plan_fee !== undefined) {
			return acc + item.plan_fee
		}
		return acc
	}, 0)
}

const videoChangeModulesSlice = createSlice({
	name: 'videoChangeModulesSlice',
	initialState: videoChangeModulesInitial,
	reducers: {
		setAdditionalModule: (state, action: PayloadAction<RecAdditionalModule[] | null>) => {
			state.modules = action.payload
			// фильтруем по подключенным
			if (action.payload === null) {
				state.filterModules = []
			} else {
				state.filterModules = action.payload.filter(item => item.module_set === true)
			}

			// пересчет суммы
			state.sum = calculateSum(state.filterModules)
		},
		setFilterModules: (state, action: PayloadAction<RecAdditionalModule>) => {
			state.filterModules = state.filterModules?.some(
				item => item.plan_id === action.payload.plan_id
			)
				? state.filterModules.filter(item => item.plan_id !== action.payload.plan_id)
				: [...state.filterModules, action.payload]

			state.sum = calculateSum(state.filterModules)
		},

		setServiceCid: (state, action: PayloadAction<string>) => {
			state.service_cid = action.payload
		},
		setConditionBlock: (state, action: PayloadAction<Condition | null | undefined>) => {
			state.condition_block = action.payload
		}
	}
})

export const { setAdditionalModule, setFilterModules, setServiceCid, setConditionBlock } =
	videoChangeModulesSlice.actions
export default videoChangeModulesSlice.reducer
