import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import { SERVICECONNECTNEW } from 'src/routes/data'
import { ServiceExtIp } from 'src/types/swagger'
import { paymentDisplay } from 'src/utils.js/paymentDisplay'

import './style.scss'

interface IProps {
	data: ServiceExtIp
	service_cid: string | number
}
const InternetIp: FC<IProps> = ({ data, service_cid }) => {
	const navigate = useNavigate()
	return (
		data && (
			<div className="connected-page__wrapper r-internet-ip">
				<div className="connected-page__accordion-heading">{data.plan.plan_name}</div>
				<div className="connected-page__inner">
					<div className="connected-page__row">
						<div className="connected-page__row-col">
							<div className="services-page__card-label">Статус</div>
							<div className="connected-page__text ">{data.ext_ip}</div>
							<div className="r-internet-ip__controls">
								{data.show_change_btn && (
									<button
										className="connected-page__link cursor-pointer"
										onClick={() =>
											navigate(
												SERVICECONNECTNEW +
													`/ip/${service_cid}/${data.ext_ip_id}/`
											)
										}
									>
										Изменить
									</button>
								)}
								{data.show_delete_btn && (
									<button
										className="connected-page__link cursor-pointer"
										onClick={() =>
											navigate(
												SERVICECONNECTNEW +
													`/ip-delete/${service_cid}/${data.ext_ip_id}/`
											)
										}
									>
										Отключить
									</button>
								)}
							</div>
						</div>
						<div className="connected-page__row-col">
							<div className="connected-page__row-item">
								<div className="services-page__card-label">Стоимость</div>
								<div className="connected-page__text">
									{paymentDisplay(
										data.plan.plan_fee,
										data.plan.fee_period,
										data.plan.fee_kind
									)}
								</div>
							</div>
							{data.plan?.end_date && (
								<div className="connected-page__row-item">
									<div className="services-page__card-label">Дата окончания</div>
									<div className="connected-page__text">
										{data.plan?.end_date}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	)
}

export default InternetIp
